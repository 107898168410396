import { useState, useMemo, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import { Option } from '../types';
import {
  getAgencyOptions,
  getAgentOptions,
  getTeamOptions
} from '../../services/options';

import { useTranslation } from '../i18n';

type useOptionsProps = {
  canListAgency?: boolean | undefined;
  canListTeam?: boolean | undefined;
  canListAgent?: boolean | undefined;
};

const useOptions = ({
  canListAgency,
  canListTeam,
  canListAgent
}: useOptionsProps) => {
  const { t } = useTranslation('common');

  const all = {
    label: t('common:all'),
    value: 'all'
  };

  const [filter, setFilter] = useState<{
    agency: Option;
    team: Option;
    agent: Option;
  }>({ agency: all, team: all, agent: all });

  const { data: agencies = [], refetch: refetchAgencies } = useQuery('agencyOptions', getAgencyOptions, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: canListAgency
  });

  const { data: teams = [], refetch: refetchTeams } = useQuery(
    'teamOptions',
    () => getTeamOptions('all'),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: canListTeam
    }
  );

  const { data: agents = [], refetch: refetchAgents } = useQuery(
    'agentOptions',
    () => getAgentOptions('all'),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: canListAgent
    }
  );

  const agencyOptions = useMemo(() => {
    return [
      all,
      ...Array.from(agencies, item => ({
        label: item.name,
        value: item.id
      }))
    ];
  }, [agencies]);

  const teamOptions = useMemo(() => {
    return [
      all,
      ...Array.from(
        canListAgency
          ? teams.filter(({ agencyId }) => agencyId === filter.agency.value)
          : teams,
        item => ({
          label: item.name,
          value: item.id
        })
      )
    ];
  }, [teams, filter.agency]);

  const agentOptions = useMemo(() => {
    return [
      all,
      ...Array.from(
        canListTeam
          ? agents.filter(({ teamId }) => teamId === filter.team.value)
          : agents,
        item => ({
          label: item.name,
          value: item.id
        })
      )
    ];
  }, [agents, filter.team]);

  return {
    agencyOptions,
    agentOptions,
    teamOptions,
    all,
    refetchAgencies,
    refetchAgents,
    refetchTeams,
    filter,
    setFilter,
    allAgencies: agencies,
    allTeams: teams,
    allAgents: agents
  };
};

export default useOptions;
