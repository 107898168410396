import adminCenterClient from './adminCenterEndPoint';

export type AgencyOptionType = {
  id: string;
  name: string;
};

export type TeamOptionType = {
  id: string;
  name: string;
  agencyId: string;
};

export type AgentOptionType = {
  id: string;
  name: string;
  teamId: string;
};

export const getAgencyOptions = async (): Promise<AgencyOptionType[]> =>
  adminCenterClient
    .get('v1/admincenter/organisation/agencies?options=1')
    .then(({ data: { items } }) => items);

export const getTeamOptions = async (id: string): Promise<TeamOptionType[]> =>
  adminCenterClient
    .get(`v1/admincenter/organisation/teams?options=1&agencyId=${id}`)
    .then(({ data: { items } }) => items);

export const getAgentOptions = async (id: string): Promise<AgentOptionType[]> =>
  adminCenterClient
    .get(`v1/admincenter/organisation/agents?options=1&teamId=${id}`)
    .then(({ data: { items } }) => items);
