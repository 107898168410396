import { useRef, useEffect } from 'react';

const useInterval = (
  func: () => void,
  delay?: number,
  options?: { leading: boolean }
): void => {
  const callback = useRef<() => void | undefined>();
  useEffect(() => {
    callback.current = func;
  }, [func]);
  const leading = options?.leading;

  useEffect(() => {
    function call() {
      if (callback.current) {
        callback.current();
      }
    }
    if (delay) {
      if (leading) {
        call();
      }
      const id = setInterval(call, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay, leading]);
};

export default useInterval;
