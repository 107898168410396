import { useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

type BoundProps = {
  left: number;
  top: number;
  width: number;
  height: number;
};

type Hook = (ref: any) => BoundProps;

const useMeasure: Hook = ref => {
  const [bounds, set] = useState<BoundProps>({
    left: 0,
    top: 0,
    width: 0,
    height: 0
  });
  const [ro] = useState(
    () =>
      new ResizeObserver(([entry]) => {
        const { top, left, width, height } = entry.contentRect;
        set({ top, left, width, height });
      })
  );
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (ref && ref.current) {
      return ro.observe(ref.current);
    }
    return () => ro.disconnect();
  }, [ref]);
  return bounds;
};

export default useMeasure;
